exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/Collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-legal-content-tsx": () => import("./../../../src/templates/LegalContent.tsx" /* webpackChunkName: "component---src-templates-legal-content-tsx" */),
  "component---src-templates-product-categories-tsx": () => import("./../../../src/templates/ProductCategories.tsx" /* webpackChunkName: "component---src-templates-product-categories-tsx" */),
  "component---src-templates-single-product-tsx": () => import("./../../../src/templates/SingleProduct.tsx" /* webpackChunkName: "component---src-templates-single-product-tsx" */)
}

